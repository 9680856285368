import React from 'react';
import HeadLine from './../../../components/HeadLine/HeadLine';
import Botao from './../../../components/Botao/Botao';
import './para-ser-corretor.css';

const Index = () => {
    return (
        <section>
            <div className="para-ser-corretor">
                <div className="overlay"></div>                                        
                <div className="wrap">
                    <HeadLine label="O que precisa para ser um corretor associado à César Rêgo?"/>
                    <div className="container">
                        <h3>
                        <span>Para ser um corretor</span> associado, você precisará:
                        </h3>

                        <ul>
                            <li><i className="fa fa-chevron-right"></i> Ser muito bom de relacionamento;</li>
                            <li><i className="fa fa-chevron-right"></i>Se interessar pelo método de trabalho autônomo e comissionado;</li>
                            <li>
                                <i className="fa fa-chevron-right"></i> 
                                Você deverá seguir os pré requisitos da lei n 13.097/2015. Nós lhe daremos todas as orientações
                                necessárias para se adequar à legislação e à nossa parceria.
                            </li>
                            <li>
                                <i className="fa fa-chevron-right"></i> 
                                Se você não tem experiência como corretor, não se preocupe. Nós te indicaremos os cursos
                                necessários para você se capacitar e aprender tudo que precisa para se tornar um profissional de
                                elite no mercado imobiliário.
                            </li>                    
                        </ul>

                        <div className="realizar-cadastro">
                            <Botao anchor={true} to="realizar-cadastro" label="Realizar cadastro" />
                        </div>
                    </div> 
                </div>      
            </div>
        </section>
    );
}
export default Index;
import React from 'react';
import CardVantagens from "./../../../components/Card/CardVantagens/CardVantagens";
import Botao from "../../../components/Botao/Botao";

const Proprietarios = () => {
    return (
        <div role="tabpanel" className="tab-pane fade " id="proprietarios">
            <div className="wrap">
                <CardVantagens icon="site">
                    Maior visibilidade para seu imóvel. Obtivemos 257,77% de crescimento do nosso site em um ano.
                </CardVantagens>
                <CardVantagens icon="acordo">
                    Realizamos diariamente transações imobiliárias
                </CardVantagens>
                <CardVantagens icon="premio">
                    Somos uma imobiliária bastante atuante em imóveis prontos, lançamentos e priorizamos a agilidade em 
                    alugar imóveis em Fortaleza
                </CardVantagens>
            </div>
            <div className="tab-btn">
                <Botao to="/cadastre_seu_imovel" label="Cadastre seu imóvel" />
            </div>
        </div>
    );
}

export default Proprietarios;
import React from 'react';
import CardVantagens from "./../../../components/Card/CardVantagens/CardVantagens";
import Botao from "../../../components/Botao/Botao";

const InquilinosCompradores = () => {
    return (
        <div role="tabpanel" className="tab-pane fade in active" id="inquilinos-compradores">
            <div className="wrap">
                <CardVantagens icon="alvo">
                    Buscamos sempre ajudar a encontrar as melhores oportunidades para o seu perfil
                </CardVantagens>
                <CardVantagens icon="mapa">
                    Temos muitas ofertas de imóveis em Fortaleza
                </CardVantagens>
                <CardVantagens icon="moedas">
                    Nossos corretores associados conhecem bastante do mercado imobiliário e vão te ajudar a fazer um excelente negócio
                </CardVantagens>                
            </div>
            <div className="tab-btn">
                <Botao anchor={true} to="imovel-ideal" label = "Encontre aqui seu imóvel" />
            </div>
        </div>
    );
}

export default InquilinosCompradores;
import React from 'react';
import HeadLine from './../../components/HeadLine/HeadLine';

const Depoimentos = () => {
    return (
        <section>
            <div className="depoimento">
                <HeadLine label="César Rêgo segundo seus cliente" />
                <div className="container">
                    <div className="wrap">
                        <div className="col">
                            <p>
                                Em <span>1984 compramos o nosso primeiro imóvel</span> com ajuda profissional do César Rêgo. Desde então, só
                                realizamos qualquer transação imobiliária com a sua intermediação. Na verdade, a César Rêgo
                                Imóveis acompanhou a formação da nossa família e a <span>construção dos nossos sonhos</span> de moradia.
                            </p>
                            <p>
                                Compramos, quando ainda éramos um casal de namorados, um apartamento de 150 metros quadrados, 
                                no bairro Dionísio Torres, ainda sem a pretensão de que fôssemos habitá-lo. Era apenas um 
                                investimento, o primeiro. Mas o tempo passou, a relação solidificou e casamos. A família foi 
                                crescendo, nossas necessidades aumentando e fomos comprando e vendendo imóveis, sempre 
                                assessorados pela César Rêgo Imóveis. <span>A confiança de que estávamos dando o passo certo e seguro</span>, 
                                dentro das nossas reais possibilidades financeiras e contemplando as nossas necessidades 
                                familiares sempre foi o que nos motivou a procurar a empresa.
                            </p>
                        </div>
                        <div className="col">
                            <p>
                                Conseguimos, em todas as situações, conquistar até mais do que imaginávamos que podíamos. E, 
                                sem dúvidas, a assessoria da <span>César Rêgo foi essencial para que realizássemos nossos sonhos</span>. Em 
                                2004 decidimos que queríamos morar em uma casa, com espaço para nossos quatro filhos, mas com 
                                segurança. E, então, mais uma vez, recorremos à César Rêgo e conseguimos concretizar o desejo. 
                                Compramos o terreno, construímos nossa casa em um condomínio fechado e estamos felizes da vida 
                                em nossa morada!
                            </p>
                            <p>
                                A competência, traduzida pelo vasto conhecimento do mercado e a facilidade nas relações; a <span>credibilidade, 
                                conquistada pela honestidade e integridade das suas ações</span>, e a sensibilidade, 
                                própria do seu gestor César Rêgo, homem digno e de bem, permitem reconhecer o perfil do cliente 
                                e identificar a solução procurada. São essas as qualidades que merecem ser destacadas e que 
                                justificaram e justificam a nossa escolha, sempre. <span>Além de bons negócios, encontramos na <span></span>César 
                                Rêgo Imóveis bons amigos</span>!
                            </p>
                        </div>
                        <p className="cliente">
                            Ana Cristina e Deusdeth Rodrigues - Juízes
                        </p>
                    </div>
                </div>    
            </div>
        </section>
    );
}
export default Depoimentos;
import React from 'react';
import HeadLine from './../../components/HeadLine/HeadLine';
import CardVantagens from "./../../components/Card/CardVantagens/CardVantagens";
import Botao from "./../../components/Botao/Botao";

const Vantagens = () => {
    return (
        <section>
            <div className="vantagens-cadastre-seu-imovel">
                <HeadLine label="Porque anunciar aqui?"/>          
                <div className="container">

                    <CardVantagens icon="site">
                    Maior visibilidade para seu imóvel. Obtivemos 257,77% de crescimento do nosso site em um ano.
                    </CardVantagens>
                    <CardVantagens icon="acordo">
                        Realizamos diariamente transações imobiliárias
                    </CardVantagens>
                    <CardVantagens icon="premio">
                        Somos uma imobiliária bastante atuante em imóveis prontos, lançamentos e priorizamos a agilidade 
                        em alugar imóveis em Fortaleza
                    </CardVantagens>

                    <div className="realizar-cadastro">
                        <Botao anchor={true} to="realizar-cadastro" label = "Cadastrar meu imóvel"/>
                    </div>    
                </div>
            </div>    
        </section>
    );
}
export default Vantagens;
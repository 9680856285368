import React from 'react';
import HeadLine from './../../components/HeadLine/HeadLine';
import Botao from "./../../components/Botao/Botao";

const Vantagens = () => {
    return (
        <section>
            <div className="como-divulgar">
                <HeadLine label="Sou proprietário, como faço para divulgar?"/>
                <h3>
                    Preencha o formulário do início desta página. E pronto. <br/>
                    Entraremos em contato para agendar uma visita e seguir com os demais detalhes da divulgação.
                </h3>
                <div className="realizar-cadastro">
                    <Botao anchor={true} to="realizar-cadastro" label = "Cadastrar meu imóvel na César Rêgo"/>                    
                </div>
            </div>
        </section>
    );
}
export default Vantagens;
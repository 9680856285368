import React, {Component} from 'react';
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Tour360 from './Label/Tour360';
import Highlight from "./../../components/Card/Highlight";

class Carousel extends Component {

    state = {
        images: [],
        fotos: [],
        total: 0,
        responsive:{
            0: {
                items: 1
            }
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.fotos.length > 0) {
            return {
                fotos: nextProps.fotos,
                images: [],
                codigo: nextProps.codigo,
                link: nextProps.link,
                tour: nextProps.tour,
                noLink: nextProps.noLink,
                total: nextProps.fotos.length,
                status_comercial: nextProps.status_comercial
            }
        }
        return null;
    }

    render() {
        let images = [];

        if (this.state.fotos.length > 0) {
            images = this.state.fotos.map( (foto, index) => {
                let item = '';

                if (this.state.link) {
                    item =  (<div key={index} className="item">
                                <a href={this.state.link} key={index}>
                                    <img src={foto.imagem} alt={'Imóvel de código ' + this.state.codigo} width="100%" height="auto"/>
                                </a>
                            </div>);
                } else {
                    item = (<div key={index} className="item">
                                <img key={index} src={foto.imagem} alt={'Imóvel de código ' + this.state.codigo} width="100%" height="auto"/>
                            </div>);

                }

                return item;
            });

        } else {
            images = <img src='/images/indisponivel.jpg' alt='Imagem indisponível'/>;
        }

        return (
            <div className="img-label-destaque">
                <Highlight destaque={false} lancamento={this.state.status_comercial}/>
                <Tour360 tour={this.state.tour} />
                <OwlCarousel
                    className="imoveis-card card-imoveis-destaque"
                    loop
                    nav
                    key={'carrossel_'+this.state.total}
                    responsive={this.state.responsive}
                    items={this.state.images.length}>
                    {images}
                </OwlCarousel>
            </div>
        );
    }
}
export default Carousel;

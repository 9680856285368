import React, { useEffect, useState } from 'react';
import { API_VENDAS } from "./constants";
import BaseServices from './../../../helpers/BaseServices';
import Headline from "../../../components/HeadLine/HeadLine";
import CarouselEmpreendimentos from './CarouselEmpreendimentos'

const Empreendimentos = () => {

    const [list, setList] = useState([]);
    
    useEffect(() => {
        fetch(`${API_VENDAS}listarempreendimentoshome`, BaseServices._getRequestInfo('venda'))
        .then(response => response.json())
        .then((result) => {
            setList(result.data);
        })
    },[]);


    return (
        <section>
            <div className="empreendimentos">
                <Headline label="Nossos Empreendimentos" />
                <div className="polular-search-list">
                    <div className="container">
                        <CarouselEmpreendimentos list={list} />
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Empreendimentos;
import BaseServices from './../../helpers/BaseServices';
import { API_EMAIL } from "./constants";
import RequestUtils from '../../config/requestUtils';

export default class Services extends BaseServices {

  detalhesDoImovel = (finalidade, codigo) => {
    let url = RequestUtils.uriResolver(finalidade);
    let method = RequestUtils.methodResolver('detalhes', finalidade);

      url += `${method}${codigo}`;

    return fetch(`${url}`, BaseServices._getRequestInfo(finalidade))
      .then(response => {
        if (!response.ok) {
          throw response.json();
        }

        return response.json();
      });
  }

  solicitarInformacoesImovel = (dados) => {
    return fetch(`${API_EMAIL}/solicitarInformacoesImovel`, BaseServices._getRequestInfo('venda', 'POST', `${JSON.stringify(dados)}`))
      .then(response => {
        if (!response.ok) {
          throw response.json();          
        }

        return response.json();
      });
  }
}
import React from 'react';
import {Link} from 'react-router-dom';
import './finalidades.css';

const Card = (props) => {
    let painel;

    if (props.finalidade === 'residencial') {
        painel =
        <div className="painel">
            <img src="/images/icons/icon-familia.png" alt="Ícone família" />
            <h3>Quer morar no bairro {props.bairro}?</h3>
            <div className="button-list">
                <Link to="/venda/apartamento" className="btn botao">Apartamentos à venda</Link>
                <Link to="/aluguel/apartamento" className="btn botao">Apartamentos para alugar</Link>                        
            </div>
        </div>
    } else {
        painel=
        <div className="painel painel-bottom">
            <img src="/images/icons/icon-pasta.png" alt="Ícone pasta" />
            <h3>Quer trabalhar no bairro {props.bairro}?</h3>
            <div className="button-list">
                <Link to="/venda/loja" className="btn botao">Lojas à venda</Link>
                <Link to="/aluguel/loja" className="btn botao">Lojas para alugar</Link> 
            </div>
        </div>
    }

    return (
        <div className="painel-finalidade">
            {painel} 
        </div>
    );
}
export default Card;
import React from 'react';
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import LayoutMain from './components/Layout/LayoutMain';
import Home from './pages/Home/Home';
import Favoritos from './pages/Favoritos/Favoritos';
import ResultadoDeBusca from './pages/ResultadoDeBusca/ResultadoDeBusca';
import Imovel from './pages/DetalhesDoImovel/Imovel';
import Bairros from './pages/Bairros/Bairros';
import BairrosPorCidade from './pages/Bairros/BairrosPorCidade';
import DetalhesDoBairro from './pages/Bairros/Detalhes/DetalhesDoBairro';
import PoliticaDePrivacidade from './pages/PoliticaDePrivacidade/PoliticaDePrivacidade';
import TermoDeUso from './pages/TermoDeUso/TermoDeUso';
import MapaDoSite from './pages/Mapa/MapaDoSite';
import QuemSomos from './pages/Empresa/QuemSomos';
import QueroSerCorretor from './pages/Corretor/QueroSerCorretor';
import CadastreSeuImovel from './pages/CadastreSeuImovel';
import Agradecimento from './pages/DetalhesDoImovel/Agradecimento';
import NotFound from './pages/NotFound/Error404';

const Routers = () => (
    <BrowserRouter>
        <Switch>
            <LayoutMain exact path="/" component={Home}/>
            {/* Redirecionamentos de URLs site antigo */}

            {/* Venda */}
            <Redirect from='/comprar/:cidade/:bairro/:id' to="/detalhes/venda/:id" />           
            <Redirect from='/comprar/:tipo' to="/busca/venda/:tipo/fortaleza/" />
            <Redirect from='/comprar/' to="/busca/venda/apartamento/fortaleza/" />

            {/* Alugar */}
            <Redirect from='/alugar/:cidade/:bairro/:id' to="/detalhes/aluguel/:id" />         
            <Redirect from='/alugar/:tipo' to="/busca/aluguel/:tipo/fortaleza/" />
            <Redirect from='/alugar/' to="/busca/aluguel/apartamento/fortaleza/" />

            <LayoutMain exact path="/favoritos" component={Favoritos} /> 
            <LayoutMain exact path="/busca/:finalidade?/:tipo?/:endereco?/:filtros?" component={ResultadoDeBusca} /> 
            <LayoutMain exact path="/aluguel/detalhes/:codigo" component={Imovel} /> 
            <LayoutMain exact path="/detalhes/:finalidade/:codigo/:slug?" component={Imovel} /> 
            
            <LayoutMain exact path="/bairro" component={Bairros} /> 
            <LayoutMain exact path="/bairro/:cidade" component={BairrosPorCidade} /> 
            <LayoutMain exact path="/bairro/:bairro/:cidade" component={DetalhesDoBairro} /> 
            <LayoutMain exact path="/politica_de_privacidade" component={PoliticaDePrivacidade} />
            <LayoutMain exact path="/termo_de_uso" component={TermoDeUso} /> {/*criar essa rota*/}
            <LayoutMain exact path="/mapa_site" component={MapaDoSite} /> 
            
            <Route exact path="/cadastre_seu_imovel" component={CadastreSeuImovel} /> 
            <Route exact path="/quem_somos" component={QuemSomos} /> 

            <Route exact path="/quero_ser_corretor" component={QueroSerCorretor} /> 
            <Route exact path="/agradecimento-contato-venda" component={Agradecimento} /> 
            <Route exact path="/agradecimento-contato-aluguel" component={Agradecimento} /> 

            <Route path="*" component={NotFound} />

        </Switch>
    </BrowserRouter>
);

export default Routers;
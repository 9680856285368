import React from 'react';
import Chart from './../../../components/Chart';

const Graficos = (props) => {
    return (
        <section>           
            <div className="imoveis-por-tipo">
                <div className="container">
                    <div className="headline">
                        <h2>Distribuição dos anúncios no bairro {props.bairro} por tipo de imóvel</h2>
                    </div>
                    <div className="col">
                        <h2>Quantidade de imóveis por tipo para <span>venda</span></h2>
                        <div className="chart">
                            <Chart data={props.venda}/>
                        </div>
                    </div>
                    <div className="col">
                        <h2>Quantidade de imóveis por tipo para <span>alugar</span></h2>
                        <div className="chart">
                            <Chart data={props.aluguel}/>                           
                        </div>
                    </div>
                </div>
            </div>            
        </section>
    );
}
export default Graficos;

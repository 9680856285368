import React from 'react';
import {Link} from 'react-router-dom';
import './ImoveisBairroEdificio.css';

const ImoveisBairroEdificio = (props) => {
    return (
        <div className="imoveis-bairro-edificio">
            <div className="card-list">
                <h2>{props.data.bairro} tem {props.data.totalImovel} imóveis
                <span>{(props.data.finalidade === 'venda' ? ' à venda' : ' para alugar')}</span></h2>
                <ul>
                    {props.data.imoveis.map((imovel, index) => (
                        <li key={index}>
                            <Link to={imovel.slug}>
                                <i className="fa fa-check"></i> {imovel.total} {imovel.tipo} 
                                {(props.data.finalidade === 'venda' ? ' à venda' : ' para alugar')}
                            </Link>
                        </li>
                    ))}                    
                </ul>
            </div>
        </div>
    );
}
export default ImoveisBairroEdificio;
import React from 'react';
import Card from './../Card/Finalidades';

const Finalidades = (props) => {
    return (
        <section>
            <div className="finalidade">
                <div className="container">
                    <Card bairro={props.bairro} finalidade="residencial"/>
                    <Card bairro={props.bairro} finalidade="comercial"/>
                </div>
            </div>
        </section>
    );
}
export default Finalidades;
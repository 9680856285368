import React from 'react';

const Item = (props) => {
    return (
        <div className="col">
            <img src={`/images/icons/icon-${props.icon}.png`} alt={props.icon}/>
            <h2>{props.children}</h2>
        </div>
    );
}
export default Item;
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Breadcrumb from "reactstrap/es/Breadcrumb";
import BreadcrumbItem from "reactstrap/es/BreadcrumbItem";
import HeadLine from './../../components/HeadLine/HeadLine';
import CardsLittle from './../../components/Card/CardLittle/CardLittle';
import './bairros.css';

const api = [
    {
        bairro: 'Benfica',
        cidade: 'Fortaleza',
        uf: 'CE',
        slug: '/bairro/benfica/fortaleza_ce'      
    },
    {
        bairro: 'Montese',
        cidade: 'Fortaleza',
        uf: 'CE',
        slug: '/bairro/montese/fortaleza_ce'
    },
    {
        bairro: 'Fátima',
        cidade: 'Fortaleza',
        uf: 'CE',
        slug: '/bairro/fatima/fortaleza_ce'
    },
    {
        bairro: 'Barra do Ceará',
        cidade: 'Fortaleza',
        uf: 'CE',
        slug: '/bairro/barra-do-ceara/fortaleza_ce'
    },
    {
        bairro: 'Aldeota',
        cidade: 'Fortaleza',
        uf: 'CE',
        slug: '/bairro/aldeota/fortaleza_ce'
    },
    {
        bairro: 'Meireles',
        cidade: 'Fortaleza',
        uf: 'CE',
        slug: '/bairro/meireles/fortaleza_ce'
    },
    {
        bairro: 'Luciando Cavalcante',
        cidade: 'Fortaleza',
        uf: 'CE',
        slug: '/bairro/luciano-cavalcante/fortaleza_ce'
    },
    {
        bairro: 'Centro',
        cidade: 'Fortaleza',
        uf: 'CE',
        slug: '/bairro/centro/fortaleza_ce'
    }
];

class Bairros extends Component {
    state = {
        cards: []
    }

    componentDidMount() {
        this.loadCards();
    }

    loadCards = () => {
        this.setState({cards: api});
    }
    render() {
        return (
            <main>              
                <div className="topo-breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <BreadcrumbItem><Link to="/">César Rêgo</Link></BreadcrumbItem>
                            <BreadcrumbItem><Link to="/bairro">Bairros</Link></BreadcrumbItem>
                            <BreadcrumbItem active>Fortaleza-CE</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                </div>
                <section>
                    <div className="list-bairros">
                        <HeadLine label="Bairros em Fortaleza"/>               
                        <div className="container">
                            <CardsLittle flag={true} cards={this.state.cards}/>                            
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default Bairros;
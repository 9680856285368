import React, {Component} from 'react';
import Breadcrumbs from './components/Breadcrumbs';
import Title from './components/Title';
import DetalhesDoImovel from './components/DetalhesDoImovel';
import ScrollIntoView from './../../components/scrollIntoView';
import {Segment} from 'semantic-ui-react';
import MetaTags from './../../components/MetaTags';
//import PontosDeInteresse from './components/PontosDeInteresse';
import Localizacao from './components/Localizacao';
import Contato from './components/Contato';
import Observacoes from './components/Observacoes';
// import Video from './components/video';
import Caracteristicas from './Caracteristicas/Caracteristicas';
import Error404 from './components/Error404'
//import ImoveisNoMesmoBairro from './CarouselImoveis/ImoveisNoMesmoBairro';
//import ImoveisRelacionados from './CarouselImoveis/ImoveisRelacionados';
import Services from './Services';
import './imovel.css';

class Imovel extends Component {
    state = {
        data: {
            imagens:[],
            caracteristicas: [],
            condominio: [],
            id:'',
            bairro:'',
            cidade:'',
            estado:'',
            latitude: 0,
            longitude: 0,  
            codigo_auxiliar: ''    
        },
        loading: true
    };
    
    constructor(props) {
        super(props);
        this._services = new Services();
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.match.params.codigo !== undefined) {
            return {
                finalidade: nextProps.match.params.finalidade,
                codigo: nextProps.match.params.codigo
            };
        }
        return null;
    }

    componentDidMount() {
        this._services.detalhesDoImovel(this.props.match.params.finalidade, this.props.match.params.codigo)
            .then(api => {
                this.setState({
                    data: api.imovel,
                    loading:false
                });
            });
    }

    render() {
        let content='';
        if (this.state.data.id !== undefined) {
            content =
            <Segment loading={this.state.loading}>

                <MetaTags 
                    title={this.state.data.tituloDetalhes} 
                    description={this.state.data.descricao} 
                    url={window.location.href} 
                    image=''
                />

                <main>
                    <Breadcrumbs modalidade={this.props.match.params.finalidade} {...this.state.data} />
                    <div className="container">
                        <Title modalidade={this.props.match.params.finalidade} slug={this.props.match.params.slug} {...this.state.data}/>
                        <div className="col-left">
                            <Caracteristicas data={this.state.data} finalidade={this.props.match.params.finalidade}/>                        
                            <DetalhesDoImovel data={this.state.data}/>
                            {/* <PontosDeInteresse data={this.state.data}/> */}
                            <Localizacao data={this.state.data} finalidade={this.props.match.params.finalidade} />

                            {/* {(this.state.data.video !== '') ? <Video video={this.state.data.video} /> : ''} */}
                            
                        </div>
                        <div className="col-right">
                            <Contato
                                id={this.state.data.id}
                                modalidade={this.props.match.params.finalidade}
                                titulo={this.state.data.tituloDetalhes}
                                codigo_auxiliar={this.state.data.codigo_auxiliar}
                                bairro={this.state.data.bairro}
                                cidade={this.state.data.cidade}
                                estado={this.state.data.estado}
                            />
                        </div>
                    </div>
                    {/* <ImoveisNoMesmoBairro imoveis={this.state.data.imoveisNoMesmoBairro} bairro={this.state.data.bairro}/> */}
                    {/* <ImoveisRelacionados imoveis={this.state.data.imoveisRelacionados}/> */}
                    <Observacoes/>
                </main>
            </Segment>
        } else {
            content = 
            <main>
                <Error404 codigo={this.props.match.params.codigo} />
            </main>               
        }


        return (            
            <ScrollIntoView>
            {content}
            </ScrollIntoView>
        );       
    }
}
export default Imovel;
import React, {Component} from 'react';
import Breadcrumb from "reactstrap/es/Breadcrumb";
import BreadcrumbItem from "reactstrap/es/BreadcrumbItem";
import {Link} from "react-router-dom";
import Headline from './../../components/HeadLine/HeadLine';
import Tag from "./../../components/Tag/Tag";
import './mapaDoSite.css';

const api = {
    venda: [
        'Imóveis à venda em Fortaleza-CE',
        'Imóveis à venda em Caucaia-CE',
        'Imóveis à venda em Horizonte-CE',
        'Imóveis à venda em Itaitinga-CE',
        'Imóveis à venda em São Gonlaço-CE',
        'Bairros à venda em Fortaleza-CE',
        'Bairros à venda em Caucaia-CE',
        'Bairros à venda em Horizonte-CE',
        'Bairros à venda em Itaitinga-CE',
        'Bairros à venda em São Gonçalo-CE',
        'Imóveis à venda em Fortaleza-CE',
        'Imóveis à venda em Caucaia-CE',
        'Imóveis à venda em Horizonte-CE',
        'Imóveis à venda em Itaitinga-CE',
        'Imóveis à venda em São Gonçalo-CE',
        'Bairros à venda em Fortaleza-CE',
        'Bairros à venda em Caucaia-CE',
        'Bairros à venda em Horizonte-CE',
        'Bairros à venda em Itaitinga-CE',
        'Bairros à venda em São Gonçalo-CE'
    ],
    aluguel : [
        'Imóveis para alugar em Fortaleza-CE',
        'Imóveis para alugar em Caucaia-CE',
        'Imóveis para alugar em Horizonte-CE',
        'Imóveis para alugar em Itaitinga-CE',
        'Imóveis para alugar em São Gonçalo-CE',
        'Bairros para alugar em Fortaleza-CE',
        'Bairros para alugar em Caucaia-CE',
        'Bairros para alugar em Horizonte-CE',
        'Bairros para alugar em Itaitinga-CE',
        'Bairros para alugar em São Gonçalo-CE'
    ]
};

class MapaDoSite extends Component {
    state = {
        tags: {}
    }

    componentWillMount() {
        this.loadCards();
    }

    loadCards = () => {
        this.setState({tags: api})        
    }

    render() {
        return (
            <main> 
                <div className="topo-breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <BreadcrumbItem><Link to="/">César Rêgo</Link></BreadcrumbItem>
                            <BreadcrumbItem active>mapa do site</BreadcrumbItem>
                        </Breadcrumb>
                    </div>    
                </div>     
                <section>
                    <div className="list-mapa">
                        <Headline label="Mapa do site"/>               
                        <div className="container">
                            <div className="list-imoveis-venda">
                                <h2>Imóveis à <span>venda</span></h2>
                                <ul>
                                    {this.state.tags.venda.map((tag, index) => (
                                        <Tag key={index} to="/venda" text={tag}/>
                                    ))}
                                </ul>                               
                            </div>
                            <div className="list-imoveis-aluguel">
                                <h2>Imóveis para <span>alugar</span></h2>
                                <ul>
                                    {this.state.tags.aluguel.map((tag, index) => (
                                        <Tag key={index} to="/aluguel" text={tag}/>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>    
            </main>
        );
    }
}
export default MapaDoSite;
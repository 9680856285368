import RequestUtils from "../config/requestUtils";

export default class BaseServices {
  static _getRequestInfo(modalidade = 'aluguel', method = 'GET', body = '', ) {
    let data = RequestUtils.tokenResolver(modalidade);

    let headers = new Headers({
      'Content-Type' : 'application/json',
      'Cache-Control': 'no-cache',
    });

    data.forEach(header => {
      headers.append(header.key, header.value);
    });

    let requestInfo = {
      method : method,
      mode: 'cors',
      headers : headers
    };

    if (body !== '') {
      requestInfo.body = body;
    }

    return requestInfo;
  };
}

import React from 'react';
import { Route } from 'react-router-dom';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import WhatsApp from "./Contato/Whatsapp";

const LayoutMain = ({children, ...rest}) => {
    return (
        <div>
            <Navbar/>
            <Header/>
            <WhatsApp/>
            {children}
            <Footer/>
        </div>
    );
}

const LayoutRoute = ({component: Component, ...rest}) => {  
    return (
        <Route {...rest} render={matchProps => (
            <LayoutMain>
                <Component {...matchProps} />
            </LayoutMain>
        )} />
    );
}
export default LayoutRoute;
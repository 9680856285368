import React, { Component } from 'react';
import Select2 from "react-select2-wrapper";
import 'react-select2-wrapper/css/select2.css';
import Utils from './../../../helpers/Util';
import { Input } from "reactstrap";
import { Segment } from 'semantic-ui-react';
import NumberFormat from "react-number-format";

const maxNumber = 10;

class SidebarSearch extends Component {
    state = {
        totalCaracteristicasImovel: maxNumber,
        totalCaracteristicasEdificio: maxNumber,
        tipo: this.props.tipo    
    }    

    closeSearch = () => {
        const element = document.querySelector('div#root');
        element.classList.remove('open-search');
    };

    buscaMobile = () => {
        this.props.buscar();
        const element = document.querySelector('div#root');
        element.classList.remove('open-search');
    };

    handleCaracteristicasImovel = () => {
        let total;
        let label = document.querySelector('#ver-caracteristicas');

        if (this.state.totalCaracteristicasImovel === this.props.caracteristicas.length) {
            label.innerHTML = 'Ver todos +';
            total = maxNumber
        } else {
            label.innerHTML = 'Ver menos -';
            total = this.props.caracteristicas.length;
        }
        
        this.setState({
            totalCaracteristicasImovel: total
        })
    }

    handleCaracteristicasEdificio = () => {
        let total;
        let label = document.querySelector('#ver-caracteristicasEdificio');

        if (this.state.totalCaracteristicasEdificio === this.props.caracsCondominio.length) {
            label.innerHTML = 'Ver todos +';
            total = maxNumber
        } else {
            label.innerHTML = 'Ver menos -';
            total = this.props.caracsCondominio.length;
        }
        this.setState({
            totalCaracteristicasEdificio: total
        })
    }    
    
    componentWillReceiveProps () {
        let label = document.querySelector('#ver-caracteristicas');
        let labelEdificio = document.querySelector('#ver-caracteristicasEdificio');

        if (this.props.caracteristicas.length < maxNumber) {
            label.style.display = 'none';
        }

        label.innerHTML = (this.props.caracteristicas.length > maxNumber ? 'Ver todos +' : '');        
        (this.props.caracteristicas.length < maxNumber ? label.style.display = 'none' : label.style.display = 'block');

        labelEdificio.innerHTML = (this.props.caracsCondominio.length > maxNumber ? 'Ver todos +' : '');
        (this.props.caracsCondominio.length < maxNumber ? labelEdificio.style.display = 'none' : labelEdificio.style.display = 'block');
        
        if (this.state.tipo !== this.props.tipo) {                 
            this.setState({
                tipo: this.props.tipo,
                totalCaracteristicasImovel: maxNumber,
                totalCaracteristicasEdificio: maxNumber
            })
            this.clearCheckbox();
        }
    }

    clearCheckbox() {
        const elements = document.querySelector('#buscaSidebar');       
        for (let i = 0; i < elements.length; i++){
            if (elements[i].type === "checkbox") {
                elements[i].checked = false;
            }
        }
    }

    listChecked = (item) => {
        let list = this.props.caracSelecionados;
        for (let i in list) {
            if (list[i].value === item) {
                return true
            }           
        }          
    }

    listCheckedEdificio = (item) => {
        let list = this.props.caracSelecionadosCondominio;
        for (let i in list) {
            if (list[i].value === item) {
                return true
            }           
        }          
    }

    render() {
        const listBairros =
            this.props.bairros.map((bairro) => (
                { text: Utils.ucWords(bairro.text), id: Utils.transliterate(bairro.id) }
            ))
        ;

        const listCidades =
            this.props.cidades.map((cidade) => (
                { text: Utils.ucWords(cidade.text), id: Utils.transliterate(cidade.id) }
            ))
        ;

        return (
            <Segment loading={this.props.loadingSideBar} basic className="segment-sidebar-resultado-de-busca">
                <div className="sidebar">
                    <h3 className="btn-close" onClick={this.closeSearch}><i className="fa fa-times"></i></h3>
                    <form id='buscaSidebar'>
                        <div className="input-destaque">
                            <div className="col-Localizacao">
                                <div className="form-group">
                                    <label htmlFor="localizacao">Localização</label>
                                    <Select2
                                        name="endereco"
                                        className="form-control"
                                        data={[
                                            { text: 'Cidades',
                                                children: listCidades,
                                            },
                                            { text: 'Bairros',
                                                children: listBairros,
                                            }
                                        ]}
                                        options={{
                                            placeholder: 'Selecione um bairro ou cidade',
                                        }}
                                        value={this.props.endereco}
                                        required={true}
                                        onSelect={(e) => this.props.handleSelect(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-tipo">
                                <div className="form-group">
                                    <label htmlFor="tipo">Tipo de imóvel</label>
                                    <Input
                                        type="select"
                                        name="tipo"
                                        value={this.props.tipo}
                                        onChange={(e) => this.props.handleSelect(e)}>
                                        {this.props.tipos.map((tipo, index) => (<option key={index} value={tipo.value}>{tipo.name}</option>))}
                                    </Input>
                                </div>
                            </div>
                            <div className="col-valorInicial">
                                <div className="form-group">
                                    <label htmlFor="valorInicial">Valor inicial</label>
                                    <NumberFormat
                                        name="valorInicial"
                                        thousandSeparator='.'
                                        decimalSeparator=','
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={this.props.filtros.valorInicial}
                                        onKeyPress={e => this.props.handleEnter(e)}
                                        onChange={e => this.props.handleOnChange(e)}
                                        className="form-control"
                                        id="valorInicial"
                                        placeholder="R$"
                                    />
                                </div>
                            </div>
                            <div className="col-valorFinal">
                                <div className="form-group">
                                    <label htmlFor="valorFinal">Valor final</label>
                                    <NumberFormat
                                        name="valorFinal"
                                        thousandSeparator='.'
                                        decimalSeparator=','
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={this.props.filtros.valorFinal}
                                        onKeyPress={e => this.props.handleEnter(e)}
                                        onChange={e => this.props.handleOnChange(e)}
                                        className="form-control"
                                        id="valorFinal"
                                        placeholder="R$"
                                    />
                                </div>
                            </div>
                            <div className="col-dormitorios">
                                <h2>Quartos</h2>
                                <div className="form-group">
                                    
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.quartos === '1' ? 'checked':false} onClick={e => this.props.handleOnClick(e)} name="quartos" value="1"/> <span>1+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.quartos === '2' ? 'checked':false} onClick={e => this.props.handleOnClick(e)} name="quartos" value="2"/> <span>2+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.quartos === '3' ? 'checked':false} onClick={e => this.props.handleOnClick(e)} name="quartos" value="3"/> <span>3+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.quartos === '4' ? 'checked':false} onClick={e => this.props.handleOnClick(e)} name="quartos" value="4"/> <span>4+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" onClick={e => this.props.handleOnClick(e)} name="quartos" value=""/>
                                        <span>Todos</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="input-body">
                            <div className="col-suites">
                                <h2>Suítes</h2>
                                <div className="form-group">
                                    <label className="radio-inline active">
                                        <input type="radio" defaultChecked={this.props.filtros.suites === '1' ? 'checked':false} name="suites" onClick={e => this.props.handleOnClick(e)} value="1"/> <span>1+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.suites === '2' ? 'checked':false} name="suites" onClick={e => this.props.handleOnClick(e)} value="2"/> <span>2+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.suites === '3' ? 'checked':false} name="suites" onClick={e => this.props.handleOnClick(e)} value="3"/> <span>3+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.suites === '4' ? 'checked':false} name="suites" onClick={e => this.props.handleOnClick(e)} value="4"/> <span>4+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="suites" onClick={e => this.props.handleOnClick(e)} value=""/>
                                        <span>Todos</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-bainheiros">
                                <h2>Banheiro</h2>
                                <div className="form-group">
                                    <label className="radio-inline active">
                                        <input type="radio" defaultChecked={this.props.filtros.banheiros === '1' ? 'checked':false} name="banheiros" onClick={e => this.props.handleOnClick(e)} value="1"/> <span>1+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.banheiros === '2' ? 'checked':false} name="banheiros" onClick={e => this.props.handleOnClick(e)} value="2"/> <span>2+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.banheiros === '3' ? 'checked':false} name="banheiros" onClick={e => this.props.handleOnClick(e)} value="3"/> <span>3+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.banheiros === '4' ? 'checked':false} name="banheiros" onClick={e => this.props.handleOnClick(e)} value="4"/> <span>4+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="banheiros" onClick={e => this.props.handleOnClick(e)} value=""/>
                                        <span>Todos</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-vagas">
                                <h2>Vagas</h2>
                                <div className="form-group">
                                    <label className="radio-inline active">
                                        <input type="radio" defaultChecked={this.props.filtros.vagas === '1' ? 'checked':false} name="vagas" onClick={e => this.props.handleOnClick(e)} value="1"/> <span>1+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.vagas === '2' ? 'checked':false} name="vagas" onClick={e => this.props.handleOnClick(e)} value="2"/> <span>2+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.vagas === '3' ? 'checked':false} name="vagas" onClick={e => this.props.handleOnClick(e)} value="3"/> <span>3+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" defaultChecked={this.props.filtros.vagas === '4' ? 'checked':false} name="vagas" onClick={e => this.props.handleOnClick(e)} value="4"/> <span>4+</span>
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="vagas" onClick={e => this.props.handleOnClick(e)} value=""/>
                                        <span>Todos</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-areaInicial">
                                <div className="form-group">
                                    <label htmlFor="areaInicial">Área inicial</label>
                                    <input 
                                        type="text" 
                                        name="areaInicial" 
                                        className="form-control" 
                                        id="areaInicial" 
                                        defaultValue={this.props.filtros.areaInicial} 
                                        onKeyPress={e => this.props.handleEnter(e)}
                                        onChange={e => this.props.handleOnChange(e)}
                                        placeholder="m²"
                                    />
                                </div>
                            </div>
                            <div className="col-areaFinal">
                                <div className="form-group">
                                    <label htmlFor="areaFinal">Área final</label>
                                    <input 
                                        type="text" 
                                        name="areaFinal" 
                                        className="form-control" 
                                        id="areaFinal" 
                                        defaultValue={this.props.filtros.areaFinal} 
                                        onKeyPress={e => this.props.handleEnter(e)}
                                        onChange={e => this.props.handleOnChange(e)}
                                        placeholder="m²"
                                    />
                                </div>
                            </div>

                            <div className="checkbox">
                                <h2>{this.props.caracteristicas.length > 0 ? 'Imóvel' : ''}</h2>
                                {this.props.caracteristicas.slice(0, this.state.totalCaracteristicasImovel).map((caracteristicas, index) => (                                    
                                    <div className="row" key={index}>
                                        <label htmlFor={caracteristicas.value}>
                                            <input 
                                                type="checkbox" 
                                                id={caracteristicas.value} 
                                                name={caracteristicas.name} 
                                                value={caracteristicas.value} 
                                                onClick={e => this.props.handleCheckedList(e)}
                                                defaultChecked={this.listChecked(caracteristicas.value)}
                                            />
                                            
                                            <div className="fa fa-check"></div>
                                            {caracteristicas.name}
                                        </label>
                                    </div>
                                ))}

                                <span id='ver-caracteristicas' className="ver-mais" onClick={this.handleCaracteristicasImovel}></span>

                                <h2>{this.props.caracsCondominio.length > 0 ? 'Edifício' : ''}</h2>
                                {this.props.caracsCondominio.slice(0, this.state.totalCaracteristicasEdificio).map((caracsCondominio, index) => (
                                    <div className="row" key={index}>
                                        <label htmlFor={caracsCondominio.value}>
                                            <input
                                                type="checkbox"
                                                id={caracsCondominio.value}
                                                name={caracsCondominio.name}
                                                value={caracsCondominio.value}
                                                onClick={e => this.props.handleCheckedListCondominio(e)}
                                                defaultChecked={this.listCheckedEdificio(caracsCondominio.value)}
                                            />
                                            <div className="fa fa-check"></div>
                                            {caracsCondominio.name}
                                        </label>
                                    </div>
                                ))}
                                <span id='ver-caracteristicasEdificio' className="ver-mais" onClick={this.handleCaracteristicasEdificio}></span>
                            </div>
                            <div className="btn-filtro">
                                <button type="button" onClick={this.buscaMobile} className="btn botao">Buscar Imóveis</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Segment>
        );
    }
}

export default SidebarSearch;
import React, { Component } from 'react';
import Tag from "../../../components/Tag/Tag";
import Headline from "../../../components/HeadLine/HeadLine";

const api = [
    {
        text: 'Casa à venda no Meireles',
        link: '/busca/venda/casa/meireles'
    },
    {
        text:  'Salas para alugar',
        link: '/busca/aluguel/sala/fortaleza'
    },
    {
        text : 'Casa para vender',
        link : '/busca/venda/casa/fortaleza'
    },
    {
        text : 'Casa à venda na Aldeota',
        link : '/busca/venda/casa/aldeota'
    },
    {
        text : 'Apartamento para alugar na Aldeota',
        link : '/busca/aluguel/apartamento/aldeota'
    },
    {
        text : 'Salas à venda',
        link : '/busca/venda/sala/fortaleza'
    },
    {
        text : 'Salas para alugar',
        link : '/busca/aluguel/sala/fortaleza'
    },
    {
        text : 'Casa para alugar',
        link : '/busca/aluguel/casa/fortaleza'
    },
    {
        text : 'Salas para alugar no Benfica',
        link : '/busca/aluguel/sala/benfica'
    },
    {
        text : 'Salas para alugar no Montese',
        link : '/busca/aluguel/sala/montese'
    },
    {
        text : 'Loja para alugar Centro',
        link : '/busca/aluguel/loja/centro'
    },
    {
        text : 'Salas para alugar no Benfica',
        link : '/busca/aluguel/sala/benfica'
    },
    {
        text : 'Salas à venda',
        link : '/busca/venda/sala/fortaleza'
    },
];

class ImoveisMaisPopulares extends Component {
    state = {
        tags: []
    }

    componentDidMount() {
        this.loadCards();
    }

    loadCards = () => {
        this.setState({tags: api})
    }

    render() {
        return (
            <section>
                <div className="mais-populares">
                    <Headline label="Busca mais populares" />
                    <div className="polular-search-list">
                        <div className="container">
                            <ul>
                                {this.state.tags.map((tag, index) => (
                                    <Tag key={index} to={tag.link} text={tag.text}/>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ImoveisMaisPopulares;
import React from 'react';
import './politicaDePrivacidade.css';
import Headline from './../../components/HeadLine/HeadLine';
import ScrollIntoView from './../../components/scrollIntoView';

const PoliticaDePrivacidade = () => {
    return (
        <ScrollIntoView>
        <main> 
            <div className="politica-de-privacidade">            
                <div className="container">
                    <div className="card">                        
                        <Headline label="Nossa Política de Privacidade" />
                        <h2>Definição</h2>
                        <p>
                            Para os fins desta Política de Privacidade, as seguintes definições serão adotadas: <br />
                            Anunciantes: são todas as pessoas e empresas que se cadastram no PORTAL CÉSAR RÊGO e que concluem
                            o cadastro de anúncio de um imóvel.                       
                        </p>

                        <h2>Política de Privacidade</h2>
                        <p>
                            São os termos contidos neste documento que tratam sobre a privacidade.                       
                        </p>

                        <h2>Informações pessoais</h2>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet tempora obcaecati, neque ut est 
                            officiis, culpa facere, eveniet ullam dignissimos commodi. Suscipit est recusandae cupiditate beatae
                            sed eveniet soluta omnis.                       
                        </p>

                        <h2>Cookies</h2>
                        <p>
                            “Cookies” são pequenos arquivos eletrônicos que são armazenados no seu navegador de internet para 
                            que nós possamos reconhecê-lo na próxima vez que Você visitar o PORTAL CÉSAR RÊGO. Você está livre 
                            para recusar quaisquer cookies que nós utilizamos, se seu navegador assim permitir. Alguns de nossos
                            Anunciantes e provedores de serviços também podem utilizar-se dos seus próprios cookies. Além disso,
                            nós e nossos provedores de serviços às vezes utilizamos “clear GIFs” ligados com cookies e outras 
                            funções de websites. Clear GIFs realizam funções administrativas sem atrapalhar a sua experiência 
                            online. Eles podem ser usados para realizar tarefas estatísticas e administrativas incluindo
                            medição do site e tráfico de páginas, verificando publicidade e posicionamento de imagens na web. 
                            Clear GIFs ou os nossos cookies não recolhem Informação Pessoal.                       
                        </p>

                        <h2>Parágrafo</h2>
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla pariatur quasi, ut reprehenderit, 
                            ipsum illo inventore deleniti officia modi quidem, quam saepe tempora at dolor voluptatum aut! 
                            Perspiciatis, perferendis harum! Adipisicing elit. Nulla pariatur quasi, ut reprehenderit, 
                            ipsum illo inventore deleniti officia modi quidem, quam saepe tempora at dolor voluptatum aut! 
                            Perspiciatis, perferendis harum.                     
                        </p>

                        <h2>Parágrafo</h2>
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla pariatur quasi, ut reprehenderit, 
                            ipsum illo inventore deleniti officia modi quidem, quam saepe tempora at dolor voluptatum aut! 
                            Perspiciatis, perferendis harum! Adipisicing elit. Nulla pariatur quasi, ut reprehenderit, 
                            ipsum illo inventore deleniti officia modi quidem, quam saepe tempora at dolor voluptatum aut! 
                            Perspiciatis, perferendis harum. Perferendis harum. Adipisicing elit. Nulla pariatur quasi, ut reprehenderit, 
                            ipsum illo inventore deleniti officia modi quidem.                 
                        </p>

                    </div>
                </div>    
            </div>   
        </main>
        </ScrollIntoView>
    );
}
export default PoliticaDePrivacidade;

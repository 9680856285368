import React from 'react';
import Botao from './../../../components/Botao/Botao';

const Ajuda = (props) => {
    return (
        <section>
            <div className="painel-ajuda">
                <div className="container">
                    <h2>Precisando de ajuda para encontrar seu imóvel no bairro {props.bairro}?</h2>
                    <Botao anchor={true} to="contato" label = "Fale com a César Rêgo" />
                </div>              
            </div>
        </section>
    );
}
export default Ajuda;
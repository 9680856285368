import React, {Component} from 'react';
import {Input} from 'reactstrap';

class FilterList extends Component {
    render () {
        return (
            <div className="btn-dropdown">
                <Input
                    name="order"
                    defaultValue={this.props.order}
                    onChange={(e) => this.props.handleOnChangeOrder(e)}
                    type="select">
                    <option value="0">Ordenar por</option>                   
                    <option value="menor-preco">Menor preço</option>
                    <option value="maior-preco">Maior preço</option>
                    <option value="menor-area">Menor área</option>
                    <option value="maior-area">Maior área</option>
                    <option value="destaque">Destaques</option>
                </Input>
            </div>
        )
    }
}

export default FilterList;
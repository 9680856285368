import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Link as LinkAnchor} from 'react-scroll';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './botao.css';

class Botao extends Component {
    render () {
        let botao;

        const wrapperClassNames = classnames(
            'btn',
            'botao',
            `${this.props.color ? this.props.color : ''}`
        );

        if (this.props.anchor) {
            botao =
                <LinkAnchor to={this.props.to} smooth={true} duration={800} className={wrapperClassNames} >
                    <i className={this.props.icon}></i> {this.props.label}
                </LinkAnchor>
        } else {
            botao =
                <Link
                    to={this.props.to}
                    className={wrapperClassNames}
                    target={this.props.target}
                    rel={this.props.rel}
                    onClick={this.props.function}
                >
                <i className={this.props.icon}></i> {this.props.label}
                </Link>
        }
        return (
            <div>
                { botao }
            </div>
        );
    };
}

Botao.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    anchor:PropTypes.bool,
    icon: PropTypes.string,
    color:PropTypes.string,
    target:PropTypes.string,
    rel: PropTypes.string
};

export default Botao;
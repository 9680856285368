import React, {Component} from 'react';
import Breadcrumb from "reactstrap/es/Breadcrumb";
import BreadcrumbItem from "reactstrap/es/BreadcrumbItem";
import {Link} from "react-router-dom";
import Util from './../../../helpers/Util';

class Breadcrumbs extends Component {

    state = {
        modalidade: '',
        tipo: '',        
        bairro: '',
        cidade: '',
        estado: '',
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.tipo !== undefined) {
            return {                
                modalidade: nextProps.modalidade.toLowerCase(),
                tipo: nextProps.tipo.toLowerCase(),
                bairro: nextProps.bairro.toLowerCase(),
                cidade: nextProps.cidade.toLowerCase(),
                estado: nextProps.estado.toLowerCase()
            }
        }
        return null;
    }

    
    render() {
        return (
            <div className="topo-breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/">César Rêgo</Link></BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link 
                                to={`/busca/${this.state.modalidade}/${Util.transliterate(this.state.tipo)}/${Util.transliterate(this.state.cidade)}`}>
                                {Util.capitalize(this.state.modalidade)}
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link 
                                to={`/busca/${this.state.modalidade}/${Util.transliterate(this.state.tipo)}/${Util.transliterate(this.state.cidade)}`}>
                                {Util.capitalize(this.state.tipo)}
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link 
                                to={`/busca/${this.state.modalidade}/${Util.transliterate(this.state.tipo)}/${Util.transliterate(this.state.bairro)}`}>
                                {Util.capitalize(this.state.bairro)}, &nbsp;
                                {Util.capitalize(this.state.cidade)} - &nbsp;
                                {Util.capitalize(this.state.estado)}
                            </Link>
                            </BreadcrumbItem>
                        <BreadcrumbItem active>{this.props.tituloDetalhes}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
        );
    }
}
export default Breadcrumbs;
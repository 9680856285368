import React from 'react';
import CardVantagens from "./../../../components/Card/CardVantagens/CardVantagens";
import Botao from "../../../components/Botao/Botao";

const Corretores = () => {
    return (
        <div role="tabpanel" className="tab-pane fade " id="corretores">
            <div className="wrap">
                <CardVantagens icon="dinheiro-na-mao">
                    Você tem a oportunidade de ganhar com venda e com aluguel de imóveis. Faça parte desse sucesso!
                </CardVantagens>
                <CardVantagens icon="clientes">
                    Como corretor associado à César Rêgo, você tem acesso ao maior portfólio de imóveis de Fortaleza e a 
                    um grande fluxo de clientes interessados
                </CardVantagens>
                <CardVantagens icon="mobile">
                    Receba as visitas marcadas em seu celular
                </CardVantagens>
            </div>
            <div className="tab-btn">
                <Botao to="/quero_ser_corretor" label="Realizar cadastro"/>
            </div>
        </div>
    );
}

export default Corretores;
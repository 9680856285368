export const APIEmailUrl = 'https://api.cesarrego.com.br/v1/email';
export const APIImobsaleUrl = 'https://api.cesarrego.com.br/v1/venda/';
export const APICadimo5Url = 'https://cadimo.imobsoft.com.br:8052/';
export const TokenAPI = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IlRva2VuIFNpdGUgQ2VzYXIgUmVnbyIsImJ1c2luZXNzIjoiQ2VzYXIgUmVnbyBJbW92ZWlzIiwiaWF0IjoxNTE2MjM5MDIyfQ._vwsV2UCnDkn0H0DTyYLD5N3M4f5q5E8aLIrhDuuNfg';
export const TokenCadimo5Api = btoa('cesar:flbPuGdCH5xMvxMh');
export const TokenConta = 'flbPuGdCH5xMvxMh';
export const MODALIDADE_ALUGUEL = 'aluguel';
export const MODALIDADE_VENDA = 'venda';
export const METODOS = {
    "aluguel" : {
        'busca': 'BuscaImoveis',
        'tipos': 'CarregaTipos',
        'destaques': 'CarregaDestaques',
        'detalhes': 'CarregaDetalhes/',
        'destaquesEspeciais': 'CarregaDestaques',
        'caracteristicas': 'CarregaCaracteristicas',
        'caracteristicasCondominio': 'CarregaCaracteristicasCondominio',
        'buscaEndereco': 'CarregaBairrosCidades',
    },
    "venda" : {
        'busca': 'imoveis',
        'tipos': 'tiposImoveisOptions',
        'destaques': 'destaques',
        'detalhes': 'detalhes?codigo=',
        'destaquesEspeciais': 'destaqueEspecial',
        'caracteristicas': 'caracteristicasOptions',
        'caracteristicasCondominio': 'caracteristicasCondominioOptions',
        'buscaEndereco': 'buscaEndereco?palavra=',
    }
};
import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import MaskedInput from 'react-text-mask';
import { withSwalInstance } from 'sweetalert2-react';
import swal from 'sweetalert2';
import Services from './Services';

/*
const tiposImoveis = [
    'Apartamento',
    'Cobertura',
    'Casa',
    'Flat',
    'Casa em Condomínio',
    'Lote em Condomínio',
    'Sala',
    'Loja',
    'Galpão',
    'Lote',
    'Prédio',
    'Vaga de Garagem'
];
*/

class Formulario extends Component {

    constructor(props){
        super(props);
        this._services = new Services(); 
        this.state = {
            nome: '',
            textMail: '',
            celular: '',
            telefone: '',
            bairro: '',
            tipo: 'Apartamento',
            finalidade: 'Venda',
            apiMessage: '' 
        }
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    clearInputs = () => {        
        this.setState({
            nome: '',
            textMail: '',
            celular: '',
            telefone: '',
            bairro: '',
            tipo: 'Apartamento',
            finalidade: 'Venda',
            }
        );        
    }

    handleClick = (e) => {
        e.preventDefault();

        let dados = 
        {   
            "nome": this.state.nome,
            "email": this.state.textMail,
            "celular": this.state.celular,
            "telefone": this.state.telefone,
            "bairro": this.state.bairro,
            "tipo": this.state.tipo,
            "finalidade": this.state.finalidade
        }

        this._services.cadastrarImovel(dados)
            .then(api => {
                this.setState({
                    apiMessage: api.message,
                    show: true
                });
            });
    }

    render() {
        const SweetAlert = withSwalInstance(swal);

        return (
            <div className="col-form" id="realizar-cadastro">
                <h2>Envie suas informações e aguarde nosso contato</h2>
                <Form onSubmit={(e) => this.handleClick(e)}>
                    <div className="col-finalidade">
                        <FormGroup>
                            <Label for="finalidade">Finalidade</Label>
                            <Input type="select" name="finalidade" id="cad-imovel-finalidade" onChange={e => this.handleOnChange(e)} value={this.state.finalidade}>
                                <option>Venda</option>
                                <option>Aluguel</option>
                                <option>Venda e aluguel</option>
                            </Input>
                        </FormGroup>                                                   
                    </div>
                    {/* <div className="col-tipo">
                        <FormGroup>
                            <Label for="tipo">Tipo de imóvel</Label>
                            <Input type="select" name="tipo" id="cad-imovel-tipo" onChange={e => this.handleOnChange(e)} value={this.state.tipo}>
                                {tiposImoveis.map((tipo, index) => (
                                    <option key={index} value={tipo}>{tipo}</option>
                                ))}                            
                            </Input>
                        </FormGroup>
                    </div> */}
                    <div className="col-tipo">
                        <FormGroup>
                            <Label for="bairro">Em qual bairro e cidade</Label>
                            <Input 
                                type="text" 
                                name="bairro" 
                                id="cad-imovel-bairro" 
                                onChange={e => this.handleOnChange(e)} 
                                value={this.state.bairro} 
                                required
                                placeholder="Ex. Meireles - Fortaleza - CE"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-nome">
                        <FormGroup>
                            <Label for="nome">Seu nome</Label>
                            <Input type="text" name="nome" id="cad-imovel-nome" onChange={e => this.handleOnChange(e)} value={this.state.nome} required />
                        </FormGroup>
                    </div>
                    <div className="col-email">
                        <FormGroup>
                            <Label for="email">Seu e-mail</Label>
                            <Input type="text" name="textMail" id="cad-imovel-email" onChange={e => this.handleOnChange(e)} value={this.state.textMail} />
                        </FormGroup>
                    </div>
                    <div className="col-fone">
                        <FormGroup>
                            <Label for="fone">Seu telefone</Label>
                            <MaskedInput
                                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/,'.', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                className="form-control"                           
                                guide={false}
                                id="cad-imovel-fone"
                                name='telefone'
                                onChange={e => this.handleOnChange(e)}
                                value={this.state.telefone}
                            />
                        </FormGroup>                                
                    </div>
                    {/* <div className="col-celular">
                        <FormGroup>
                            <Label for="celular">Seu celular</Label>
                            <MaskedInput
                                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                className="form-control"                           
                                guide={false}
                                id="cad-imovel-celular"
                                name='celular'
                                onChange={e => this.handleOnChange(e)}
                                value={this.state.celular}
                            />
                        </FormGroup>                               
                    </div>  */}
                    <div className="col-botao">    
                        <Button type="submit" className="btn botao">Quero anunciar meu imóvel</Button>
                    </div>    
                </Form>

                <SweetAlert
                    show={this.state.show}
                    text={this.state.apiMessage}
                    onConfirm={() => this.setState({ show: false }, () => this.clearInputs())}
                />                 
            </div>
        ); 
    }
}
export default Formulario;
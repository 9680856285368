import React from 'react';
import './tour360.css';

const Tour360 = (props) => {
    if (props.tour !== '') {
        return (
            <div className='label-360'>
                <a href={props.tour} target="_blank" rel="noopener noreferrer">
                    <i className='fa fa-street-view'></i> Tour 360&ordm;
                </a>
            </div>
        );
    } else {
        return '';
    }
}

export default Tour360;
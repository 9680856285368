import React from 'react';
import './termoDeUso.css';
import Headline from './../../components/HeadLine/HeadLine';
import ScrollIntoView from './../../components/scrollIntoView';

const TermoDeUso = () => {
    return (
        <ScrollIntoView>
        <main> 
            <div className="termo-de-uso">
                <div className="container">            
                    <div className="card">
                        <Headline label="Termo de Uso - César Rêgo" />              
                        <p>
                            CÉSAR RÊGO IMOVEIS LTDA, CNPJ/MF 99.999.999/0001-09,(doravante César Rêgo Imóveis” ou “nós”) 
                            oferece uma plataforma online para divulgação, intermediação e administração de locações 
                            imobiliárias.                       
                        </p>                
                        <p>
                            Estes Termos de Uso regem a utilização dos serviços disponibilizados através do
                            site www.cesarrego.com.br ou de quaisquer outros sites, aplicativos móveis ou
                            canais de atendimento (doravante coletivamente nominados “Site”) oferecidos pela
                            César Rêgo.                      
                        </p>               
                        <p>
                            Os documentos listados abaixo são parte integrante destes Termos de Uso e os
                            complementam na regulamentação e formalização de atividades e serviços
                            específicos:                     
                        </p>

                        <h2>Aceitação mandatória para utilização do Site e Serviços da César Rêgo</h2>
                        <p>
                            A aceitação destes Termos de Uso é indispensável à utilização do Site e de
                            quaisquer outros Serviços prestados pela César Rêgo. Ao acessar ou utilizar o Site
                            ou quaisquer outros serviços oferecidos pela César Rêgo você declara ter lido,
                            entendido e concordado com estes Termos, independentemente de você ter
                            registrado no Site ou assinado algum contrato físico. Se você aceitar ou concordar
                            com estes Termos em nome de uma empresa ou outra entidade jurídica, você
                            declara e garante que tem autoridade para obrigar que a empresa ou outra entidade
                            legal cumpra estes Termos e, em tal caso, “você” e “seu” irão se referir à empresa
                            ou outra entidade jurídica. Se você não concorda com estes Termos, você não deve
                            utilizar o Site ou qualquer serviço prestado pela César Rêgo. A utilização do Site
                            ou outros serviços em discordância com estes Termos está sujeita a penalidades
                            civis e criminais.                     
                        </p>

                        <h2>Modificação dos Termo</h2>
                        <p>
                            A César Rêgo reserva-se o direito de modificar estes Termos, os Termos
                            Adicionais ou as características do Site e Serviços a qualquer momento, a seu
                            exclusivo critério e sem aviso prévio. Se modificarmos estes Termos, pelo menos 10
                            dias antes da modificação entrar em vigor, nós publicaremos uma nova versão no
                            Site com a respectiva data da última atualização. Ao continuar a utilizar o Site ou
                            Serviços após a data em que uma alteração entrar em vigor, você indica aceitação
                            da nova versão em sua totalidade. Se você não concordar com uma nova versão
                            destes Termos, você deverá interromper a utilização do Site e Serviços. As
                            alterações não vigorarão em relação a negociações e anúncios iniciados antes da
                            data em que a nova versão entrou em vigor. Estes casos serão regulados pela
                            redação anterior destes Termos.                    
                        </p>

                        <h2>Conta de Usuário</h2>
                        <p>
                            Qualquer pessoa pode pesquisar e visualizar anúncios sem se registrar no Site da
                            César Rêgo. Para acessar as demais áreas e funcionalidades do Site você
                            precisará criar uma Conta de Usuário (“Sua Conta”, “Sua Conta da César Rêgo”).
                            Você pode criar uma conta diretamente através do Site da César Rêgo ou através
                            dos serviços de login do Facebook ou do Google+.                
                        </p>
                    </div>
                </div>    
            </div>   
        </main>
        </ScrollIntoView>
    );
}
export default TermoDeUso;
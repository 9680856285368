import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Form, Input} from "reactstrap";
import Breadcrumb from "reactstrap/es/Breadcrumb";
import BreadcrumbItem from "reactstrap/es/BreadcrumbItem";
import HeadLine from './../../components/HeadLine/HeadLine';
import CardsLittle from './../../components/Card/CardLittle/CardLittle';
import './bairros.css';

const api = [
    {
        cidade: 'Fortaleza',
        uf: 'CE',
        totalBairros: 120,
        slug: '/bairro/fortaleza_ce'
    },
    {
        cidade: 'Caucaia',
        uf: 'CE',
        totalBairros: 10,
        slug: '/bairro/caucaia_ce'
    },
    {
        cidade: 'Horizonte',
        uf: 'CE',
        totalBairros: 5,
        slug: '/bairro/horizonte_ce'
    },
    {
        cidade: 'Itaitinga',
        uf: 'CE',
        totalBairros: 20,
        slug: '/bairro/itaitinga_ce'
    },
    {
        cidade: 'Fortaleza',
        uf: 'CE',
        totalBairros: 120,
        slug: '/bairro/fortaleza_ce'
    },
    {
        cidade: 'Caucaia',
        uf: 'CE',
        totalBairros: 10,
        slug: '/bairro/caucaia_ce'
    },
    {
        cidade: 'Horizonte',
        uf: 'CE',
        totalBairros: 5,
        slug: '/bairro/horizonte_ce'
    },
    {
        cidade: 'Itaitinga',
        uf: 'CE',
        totalBairros: 20,
        slug: '/bairro/itaitinga_ce'
    }
];

class Bairros extends Component {
    state = {cards: []}

    componentDidMount() {
        this.loadCards();
    }

    loadCards = () => {
        this.setState({cards: api});
    }

    render() {
        return (
            <main> 
                <div className="topo-breadcrumb">
                    <div className="container">
                        <Breadcrumb>
                            <BreadcrumbItem><Link to="/">César Rêgo</Link></BreadcrumbItem>
                            <BreadcrumbItem active>bairros</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                </div>
                <section>
                    <div className="list-bairros">
                        <HeadLine label="Encontre informações sobre imóveis no seu bairro favorito"/>               
                        <div className="container">
                            <div className="busca-bairros">
                                <Form>
                                    <Input type="text" placeholder="Pesquise por um bairro"/>
                                </Form>
                                <h3>Ou veja a lista bairros de uma das cidades abaixo:</h3>
                            </div>

                            <CardsLittle cards={this.state.cards}/>
                            
                        </div>
                    </div>
                </section>    
            </main>
        );
    }
}
export default Bairros;
import React, { Component } from 'react';
import Breadcrumb from "reactstrap/es/Breadcrumb";
import BreadcrumbItem from "reactstrap/es/BreadcrumbItem";
import {Link} from "react-router-dom";

class Breadcrumbs extends Component {
    render() {
        return (
            <div className="topo-breadcrumb">
                <div className="container">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/">César Rêgo</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Imóveis para {this.props.finalidade}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
        );
    }
}
export default Breadcrumbs;
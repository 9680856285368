import React from 'react';
import HeadLine from './../../components/HeadLine/HeadLine';
import Botao from "./../../components/Botao/Botao";

const Vantagens = () => {
    return (
        <section>
            <div className="relacionamento">
                <div className="overlay"></div>                                        
                <div className="wrap">
                    <HeadLine label="Excelente relacionamento com nossos clientes"/>
                    <div className="container">
                        <h3>
                            Dizer que a <span>César Rêgo Imóveis</span> abre as portas para você se sentir em casa não é força de
                            expressão: somos de fato uma empresa que <span>valoriza a proximidade</span> com os nossos 
                            clientes. Do atendimento à diretoria, <span>estamos sempre acessíveis</span> para uma boa
                            conversa, e isso faz toda a diferença.
                        </h3>
                        <div className="realizar-cadastro">
                            <Botao anchor={true} to="realizar-cadastro" label = "Cadastrar meu imóvel"/>
                        </div>
                    </div> 
                </div>      
            </div>
        </section>
    );
}
export default Vantagens;
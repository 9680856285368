import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import RedeSocial from "./RedeSocial";

class Footer extends Component {

    mudarFinalidade = (url) => {
        window.location = url;
    };

    now = new Date();   

    render(){
        return (
            <footer>
                <div className="container">
                    <div className="col">
                        <h4>A César Rêgo</h4>
                        <ul>
                            <li><Link to="/quem_somos">Quem somos</Link></li>
                            <li><a href="/blog" rel="noopener noreferrer" target="_blank">Blog</a></li>
                            <li><Link to="/quero_ser_corretor">Quero ser corretor</Link></li>
                            <li><Link to="" onClick={() => this.mudarFinalidade('/busca/venda/apartamento/fortaleza')}>Imóveis à venda</Link></li>
                            <li><Link to="" onClick={() => this.mudarFinalidade('/busca/aluguel/apartamento/fortaleza')}>Imóveis para alugar</Link></li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>Proprietário</h4>
                        <ul>
                            <li><a href="https://areaclienteweb.com.br/apps/AreaClienteWeb.dll?token=[ákYj8j]_g8aegn]akSHHIQMHIIHHHIOL" rel="noopener noreferrer" target="_blank">Proprietário</a></li>
                            <li><a href="https://areaclienteweb.com.br/apps/AreaClienteWeb.dll?token=[ákYj8j]_g8aegn]akSHHIQMHIIHHHIOL" rel="noopener noreferrer" target="_blank">Inquilino</a></li>
                        </ul>
                    </div>
                    <div className="col">
                        {/* <h4>Outras buscas</h4>
                        <ul>
                            <li><Link to="/bairro">Bairros</Link></li>
                            <li><Link to="/condominios_edificios">Condomínios e edifícios</Link></li>
                        </ul> */}
                    </div>
                    <div className="col-contato">
                        <div className="horario-funcionamento">
                            <h3>
                                <i className="fa fa-clock-o"></i> Horário de funcionamento
                            </h3>
                            <p>
                                Segunda a Sexta - 08:00 às 18:00h <br />
                                Sábado - 08:00 às 12:00h
                            </p>
                        </div>
                        <div className="central-atendimento">
                            <h3>
                                <i className="fa fa-phone"></i> Central de atendimento
                            </h3>
                            <a href="tel:+558533053000">
                                <p>
                                    <sup>(85)</sup> 3305-3000
                                </p>
                            </a>
                        </div>
                        <div className="endereco">
                            <h3><i className="fa fa-map-marker"></i> Nosso endereço</h3>
                            <p>
                                Rua Osvaldo Cruz, 1 - 12º andar - Edifício Beira Mar Trade Center <br />
                                Fortaleza, Ceará - CRECI 662J
                            </p>
                            <a href="https://www.google.com/maps/dir//C%C3%A9sar+R%C3%AAgo+Im%C3%B3veis,+Beira+Mar+Trade+Center+-+R.+Osvaldo+Cruz,+1+-+12%C2%B0+andar+-+Meireles,+Fortaleza+-+CE,+60125-150/@-3.7258035,-38.4993638,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x7c7486e4ca59423:0x8619f8a9e9ae11ff!2m2!1d-38.4971751!2d-3.7258089" target="_blank" rel="noopener noreferrer"><p className="como-chegar">COMO CHEGAR</p></a>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="col-left">
                            <Link to="/politica_de_privacidade">Política de privacidade</Link>
                            <Link to="/termo_de_uso">Termo de uso</Link>
                            {/* <Link to="/mapa_site">Mapa do site</Link> */}
                        </div>
                        <div className="col-center">
                            <p><i className="fa fa-copyright"></i> {this.now.getFullYear()} César Rêgo - Todos os direitos reservados</p>
                        </div>
                        <div className="col-right">
                            <RedeSocial to="https://www.facebook.com/CesarRegoImoveis?fref=ts" icon="fa fa-facebook"/>
                            <RedeSocial to="https://www.instagram.com/cesarregoimoveis/?hl=pt" icon="fa fa-instagram"/>
                            <RedeSocial to="https://www.youtube.com/channel/UCFwwgasfeUcRthRxMpcBM0g" icon="fa fa-youtube"/>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Form from './Form';
import Botao from '../../../components/Botao/Botao';

class BuscaContent extends Component {
    constructor() {
        super();
        this.state = {
            form: 'SearchLocation',
            labelBtn: 'Busca por código'
        }
    }

    showForm() {
        let form, label;

        if (this.state.form === 'SearchLocation') {
            form = 'SearchCode';
            label = 'Busca por Localização'
        } else {
            form = 'SearchLocation';
            label = 'Busca por código';
        }

        this.setState({
            form: form,
            labelBtn: label
        });
    }

    render() {
        return (
            <section>
                <div className="safearea" id="imovel-ideal">
                    <div className="overlay"></div>
                    <div className="wrap">
                        <h1>César Rêgo Imóveis - Imobiliária em Fortaleza</h1>
                        <h2>Encontre aqui os melhores imóveis</h2>

                        <Form type={this.state.form} {...this.props}/>

                        <div className="search-by-code">
                            <Botao to="/" function={this.showForm.bind(this)} label={this.state.labelBtn} color="branco" />
                        </div>
                    </div>
                    <div className="link-cadastro-seu-imovel">
                        <Link to="/cadastre_seu_imovel">Anuncie seu imóvel com a gente</Link>
                    </div>
                </div>
            </section>
        );
    }
}

export default BuscaContent;
import React from 'react';
import CardDestaque from "./CardDestaque";
import Headline from "../../../components/HeadLine/HeadLine";

const ImoveisDestaques = () => {
    return (
        <section>
            <div className="imoveis-destaque">
                <Headline label="Imóveis para comprar ou alugar" />
                <div className="content">
                    <div className="container">
                        <CardDestaque />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ImoveisDestaques;
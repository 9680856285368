import BaseServices from './../../../helpers/BaseServices';
import { API_EMAIL } from "./constants";

export default class Services extends BaseServices {

  contato = (dados) => {
    return fetch(`${API_EMAIL}/contato`, BaseServices._getRequestInfo('venda','POST', `${JSON.stringify(dados)}`))
      .then(response => {
        if (!response.ok) {
          throw response.json();
        }

        return response.json();
      });
  }
}
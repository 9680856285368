import React, { PureComponent } from 'react';
import Breadcrumbs from "./components/Breadcrumbs";
import SidebarSearch from "./components/SidebarSearch";
import Content from "./components/Content";
import './resultadoDeBusca.css';
import Services from "./Services";
import ServicesBusca from "./../Home/Busca/Services";
import fields from "./fields";
import ScrollIntoView from './../../components/scrollIntoView';
import Util from './../../helpers/Util';

class ResultadoDeBusca extends PureComponent{

    state= {
        ...this.props,
        ...fields,
        intervalId: 0
    };

    constructor(props) {
        super(props);
        this._services = new Services();
        this._servicesBusca = new ServicesBusca();        
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let params = (nextProps.match.params.filtros ? nextProps.match.params.filtros : '');
        let params_filtros = {};

        if (params !== '') {
            let filtros = '';
            let padrao = [];
            filtros = params.split('+');

            for (let i in filtros) {                
                padrao = filtros[i].split('-');
                params_filtros[padrao[1]] = padrao[0];                
            }
        }

        if (
            nextProps.match.params.finalidade === prevState.finalidade ||
            nextProps.match.params.tipo === prevState.tipo ||
            nextProps.match.params.endereco === prevState.endereco ) {
            return null;
        }        
        
        return {
            finalidade: nextProps.match.params.finalidade,
            tipo: nextProps.match.params.tipo,
            endereco: nextProps.match.params.endereco,
            filtros: params_filtros          
        };
    }

    componentDidMount () {
        this._services.busca(
            this.state.finalidade,
            this.state.tipo,
            this.state.endereco,
            this.state.filtros,
            this.state.page,
            this.state.order,
            this.state.limit,
            this.state.offset,
            this.state.caracSelecionados,
            this.state.caracSelecionadosCondominio
        )
            .then(response => {
                this.setState({
                    imoveis: response.resultado.imoveis,
                    total: response.resultado.total,
                    loadingContent: false,               
                });
            })
            .catch(e => console.log(e));
        
        this._services.getCaracteristicasImovel(this.state.finalidade, this.state.tipo)
            .then(caracteristicas => this.setState({caracteristicas: caracteristicas.options}));    
        
        this._services.getCaracteristicasCondominio(this.state.finalidade, this.state.tipo)
            .then(caracsCondominio => this.setState({caracsCondominio: caracsCondominio.options}));

        this._servicesBusca.getTiposImoveisOptions(this.state.finalidade)
            .then(tipos => this.setState({tipos: tipos.options}));

        this._servicesBusca.getbuscaEndereco(this.state.finalidade,'')
            .then(endereco => this.setState({
                bairros: endereco.resultado.bairros,
                cidades: endereco.resultado.cidades,
                loadingSideBar:false
            }));
    }

     handleSelect = (e) => {         
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : ''),
            offset: 0,
            caracSelecionados:[],
            caracSelecionadosCondominio: [],
        }, () => {
            this.buscar();
        });
    };

    handlePaginacao = (name, value) => {
        this.setState({
            [name]: value
        }, () => {
            this.buscar();
        });
    };    

    handleOnChangeOrder = (e) => {
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value : '')
            }
        }, () => {
            this.buscar();
        });
    };

    handleOnClick = (e) => {           
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value : '')
            },
            offset: 0
        }, () => this.buscar());
    };

    handleCheckedList = (e) => {
        let list = this.state.caracSelecionados;
        (
            e.target.checked ? 
            list.push({name: e.target.name , value: e.target.value }) : 
            list.splice(list.findIndex(index => index.value === e.target.value), 1)
        )
        this.setState({
            caracSelecionados: list,
            offset: 0
        }, () =>this.buscar())
    }

    handleCheckedListCondominio = (e) => {
        let list = this.state.caracSelecionadosCondominio;        
        (
            e.target.checked ? 
            list.push({name: e.target.name , value: e.target.value }) : 
            list.splice(list.findIndex(index => index.value === e.target.value), 1)
        )
        this.setState({
            caracSelecionadosCondominio: list,
            offset: 0
        }, () =>this.buscar())
    }

    handleOnChange = (e) => {
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value.replace('.','').replace('.','').replace(',00','') : '')
            }
        });
    };

    handleMoney = (e) => {
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value.replace('.','').replace(',00','') : '')
            }
        });
    };

    handleEnter = (e) => {
        if (e.key === 'Enter') {
            this.setState({              
                offset: 0
            }, () => {
                this.buscar();
            });
        }
    };

    mudarFinalidade = (url) => {
        window.location = url;
    };

    scrollStep() {
        if (window.pageYOffset === 0) {
        clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 10);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), 5);
        this.setState({ intervalId: intervalId });
    }

    adicionarFiltros() {
        let filtros = this.state.filtros;
        // let caracsImovel = '';
        // let caracsEdificio = '';
        let resultado = "";
        
        for (let i in filtros) {
            if (filtros[i] !== ''){
                if (i !== 'order'){ resultado += filtros[i] + '-' + i + '+';}
            }
        }

        // if (this.state.caracSelecionados.length > 0) {
        //     caracsImovel = (resultado === "" ? 'caracteristicas=' : '++caracteristicas=') ;
        //     this.state.caracSelecionados.map(item => [
        //         caracsImovel += item.name + '+'
        //     ]);
        // }

        // if (this.state.caracSelecionadosCondominio.length > 0) {
        //     caracsEdificio = (resultado === "" && caracsImovel === "" ? 'edificio=' : '++edificio=') ;
        //     this.state.caracSelecionadosCondominio.map(item => [
        //         caracsEdificio += item.name + '+'
        //     ]);
        // }
        return '/'+ resultado.substr(0,(resultado.length - 1))
        // + Util.transliterate(caracsImovel.substr(0,(caracsImovel.length - 1))) +
        // Util.transliterate(caracsEdificio.substr(0,(caracsEdificio.length - 1)));
    }

    buscar = () => {
        this.scrollToTop();      
        this.setState({loadingSideBar:true, loadingContent:true, imoveis: []});
        this._services.busca(
            this.state.finalidade, 
            this.state.tipo, 
            this.state.endereco, 
            this.state.filtros, 
            this.state.page, 
            this.state.order, 
            this.state.limit, 
            this.state.offset, 
            this.state.caracSelecionados, 
            this.state.caracSelecionadosCondominio)
            .then(response => {                
                this.props.history.push('/busca/'+this.state.finalidade.toLowerCase()+'/'+Util.transliterate(this.state.tipo)+'/'+this.state.endereco.toLowerCase()+this.adicionarFiltros());
                this.setState({
                    imoveis: response.resultado.imoveis,
                    total: response.resultado.total,
                    loadingSideBar: false,
                    loadingContent: false
                });
            })
            .catch(e => console.log(e));

        this._services.getCaracteristicasImovel(this.state.finalidade, this.state.tipo)
            .then(caracteristicas => this.setState({caracteristicas: caracteristicas.options}));
        
        this._services.getCaracteristicasCondominio(this.state.finalidade, this.state.tipo)
            .then(caracsCondominio => this.setState({caracsCondominio: caracsCondominio.options}));    
    };

    render() {
        return (
            <ScrollIntoView>
                <main>
                    <Breadcrumbs finalidade={this.state.finalidade}/>
                    <div className="container-fluid">
                        <SidebarSearch
                            {...this.state}
                            buscar={this.buscar}
                            handleSelect={e => this.handleSelect(e)}
                            handleEnter={e => this.handleEnter(e)}
                            handleMoney={(filterName, values) => this.handleMoney(filterName, values)}
                            handleOnClick={e => this.handleOnClick(e)}
                            handleOnChange={e => this.handleOnChange(e)}
                            handleCheckedList={e => this.handleCheckedList(e)}                            
                            handleCheckedListCondominio={e => this.handleCheckedListCondominio(e)}                            
                        />
                        <Content
                            {...this.state}
                            handlePaginacao={(name, value) => this.handlePaginacao(name, value)}
                            handleOnChangeOrder={e => this.handleOnChangeOrder(e)}
                            mudarFinalidade={this.mudarFinalidade}
                            filtrosAdicionados={this.adicionarFiltros()}                        
                            />
                    </div>                
                </main>
            </ScrollIntoView>
        );
    }
}

export default ResultadoDeBusca;
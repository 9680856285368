import React, { Component } from 'react';
import {Form, FormGroup, Input, Button} from "reactstrap";
import {Segment} from 'semantic-ui-react';
import { withSwalInstance } from 'sweetalert2-react';
import swal from 'sweetalert2';
import MaskedInput from 'react-text-mask';
import Util from './../../../helpers/Util';
import Services from '../Services';

class Contato extends Component {

    constructor(props){
        super(props);
        this._services = new Services(); 
        this.state = {
            empreendimento:'',
            nome:'',
            email:'',
            textMail: '',
            telefone:'',
            mensagem: '',
            apiMessage: '',
            contato: '',
            loading: false         
        }               
    }

    static getDerivedStateFromProps(nextProps, prevState) {    
        if (nextProps.bairro !== prevState.bairro) {
            return {
                ...nextProps,
                empreendimento: nextProps.codigo_auxiliar,
                mensagem: 'Olá, gostaria de receber mais informações sobre o imóvel no bairro '
                + nextProps.bairro + ', '
                + Util.capitalize(nextProps.cidade) + ' - '
                + Util.capitalize(nextProps.estado) + '. O código do imóvel é '
                + nextProps.id +'. Aguardo retorno.'
            };
        }
        return null;
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({loading: true, show: false});

        let url = `https://www.cesarrego.com.br/agradecimento-contato-${this.state.modalidade}`;   
        window.gtag_report_conversion(url);

        if(this.state.modalidade === 'venda'){            
            window.chama();  
        }
              
        let dados = 
        {   
            "codigo": this.props.id,
            "modalidade": this.props.modalidade,
            "link": window.location.href,
            "titulo": this.props.titulo,
            "nome": this.state.nome,
            "email": (this.state.modalidade === 'venda' ? this.state.email : this.state.textMail), 
            "telefone": this.state.telefone,
            "mensagem": this.state.mensagem,
            "contato": this.state.contato
        }
        
        this._services.solicitarInformacoesImovel(dados)            
            .then(api => {
                this.setState({loading: false});
                if (api.code === 1) {                    
                    document.interesseImovelRD.action=`/agradecimento-contato-${this.state.modalidade}`;
                    document.interesseImovelRD.submit();
                }
                if (api.code === 2) {
                    this.setState({
                        apiMessage: `${api.message} Repita a operação.`,
                        show: true
                    })
                }                                
            });
    }
    
    handleOnChangeFinalidade = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : '')
        });
    };

    render() {
        const SweetAlert = withSwalInstance(swal);

        return (
            <Segment loading={this.state.loading}>
            <div id="search-code">
                <Form onSubmit={(e) => this.handleClick(e)} name="interesseImovelRD">
                    <h2>Saiba mais sobre este imóvel</h2>
                    <FormGroup>
                        <Input 
                            name="empreendimento" 
                            id="iptEmpreendimento" 
                            type="hidden"
                            onChange={e => this.handleOnChange(e)} 
                            value={this.state.empreendimento} 
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            name="nome" 
                            id="iptNome" 
                            type="text" 
                            placeholder="Seu nome" 
                            required 
                            onChange={e => this.handleOnChange(e)} 
                            value={this.state.nome} 
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            name={this.props.modalidade === 'venda' ? "email" : "textMail"} 
                            id="iptEmail" 
                            type={this.props.modalidade === 'venda' ? "email" : "text"} 
                            placeholder="Seu e-mail" 
                            required  
                            onChange={e => this.handleOnChange(e)} 
                            value={this.props.modalidade === 'venda' ? this.state.email : this.state.textMail}
                        />
                    </FormGroup>
                    <MaskedInput
                            mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className="form-control"
                            placeholder="Seu celular"                            
                            guide={false}
                            name='telefone'
                            id="iptTelefone"
                            required={true}
                            value={this.state.telefone}
                            onChange={e => this.handleOnChange(e)}
                    />
                    <FormGroup>                            
                          <Input
                              // id="form-fluid-departamento" 
                              type="select"
                              name="contato"
                              onChange={(e) => this.handleOnChangeFinalidade(e)}
                              required
                          >
                              <option value="">Forma de contato</option>
                              <option value="whatsapp">Whatsapp</option>
                              <option value="ligação">Ligação</option>
                              <option value="email">Email</option>
                          </Input>
                    </FormGroup>                                         
                    <FormGroup>
                        <Input name="mensagem" id="iptMensagem" type="textarea" rows="3" onChange={e => this.handleOnChange(e)} value={this.state.mensagem} />
                    </FormGroup>       
                    <span className="detail-form">Confira se os seus dados estão corretos, pois entraremos em contato através deles.</span> 
                    <Button type='submit' className="btn botao">
                        Receber informações
                    </Button>                    
                </Form>
                
                <SweetAlert
                    show={this.state.show}
                    title="Erro!"
                    text={this.state.apiMessage}
                    showCancelButton
                    cancelButtonText='Cancelar'
                />
            </div>
            </Segment>
        );
    }
}

export default Contato;

import React, {Component} from 'react';
import { API_VENDAS } from "./constants";
import BaseServices from './../../../helpers/BaseServices';
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './carouselEmpreendimentos.css';

class CarouselEmpreendimentos extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            list:[],
            responsive:{
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                }
            }
        }
    }

    componentDidMount(){
        this.montarArrayEmpreendimentos();
    }

    montarArrayEmpreendimentos = () => {
        fetch(`${API_VENDAS}listarempreendimentoshome`, BaseServices._getRequestInfo('venda'))
        .then(response => response.json())
        .then((result) => {
            this.setState({list: result.data});
        })
    }


    render() {

        return (
            <div className="carousel-empreendimentos">
                <OwlCarousel 
                    autoplay 
                    autoplayHoverPause
                    loop 
                    nav 
                    responsive={this.state.responsive}
                >                    
                    {this.state.list.map(empreendimento => (
                        <div key={empreendimento.id} className="item"> 
                            <div className="card-carousel">
                                <a href={empreendimento.link} target="_black" rel="noopener noreferrer">
                                    <img src={`https://${empreendimento.image}`} alt={empreendimento.nome}/>                            
                                </a>
                                <div className="wrap">
                                    <h2>{empreendimento.nome}</h2>
                                    <a className="btn botao azul" href={empreendimento.link} target="_black" rel="noopener noreferrer">Mais detalhes</a>
                                </div>
                            </div>                                                   
                        </div>
                    ))} 
                </OwlCarousel>               
            </div>
        );
    }
}
export default CarouselEmpreendimentos;

import React from 'react';
import HeadLine from '../../components/HeadLine/HeadLine';
import Card from './component/Card';

const Negocios = () => {
    return (
        <section>
            <div className="negocios">

                <HeadLine label="Como a César Rêgo vai te ajudar a fazer bons negócios?" />

                <div className="container">
                    <Card icon="escudo" title="Para nós, sua segurança vem em primeiro lugar">
                        Adotamos o Padrão César Rêgo de Qualidade, que envolve uma rigorosa análise de toda a 
                        documentação do imóvel e das partes envolvidas antes de você fechar negócio.    
                    </Card>

                    <Card icon="acordo" title="Compre, venda ou alugue com muito mais agilidade">
                    Contamos com uma grande oferta de imóveis disponíveis em Fortaleza, e recebemos uma vasta quantidade 
                    de clientes interessados. Com a gente você compra, vende ou aluga seu imóvel com rapidez e agilidade.    
                    </Card>

                    <Card icon="corretores" title="Visite com os melhores corretores do mercado">
                        A César Rêgo é parceira e trabalha com os melhores corretores do mercado. Você tem a 
                        garantia de que será acompanhado sempre por profissionais de grande competência e conhecedores do
                        mercado imobiliário.    
                    </Card>
                </div>
            </div>
        </section>
    );
}
export default Negocios;
import React, { Component } from 'react';
import {Form, Input, Button} from "reactstrap";
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import Services from './Services';
import Utils from './../../../helpers/Util';
import {Segment} from 'semantic-ui-react';

class SearchLocation extends Component {

    state = {
        finalidade: 'venda',
        tipo: 'apartamento',
        endereco:'fortaleza',
        tipos:[
           {    
                'name': 'Selecione',
                'value': 0
            }
        ],
        bairros: [],
        cidades: [],
        empreendimentos: [],
        logradouros: [],
        loading:true
    };

    constructor(props) {
        super(props);
        this._services = new Services();
      };

    UNSAFE_componentWillMount(){
        this._services.getTiposImoveisOptions('venda')
            .then(tipos => this.setState({tipos: tipos.options}));
        this._services.getbuscaEndereco('venda','')
            .then(endereco => this.setState({
                bairros: endereco.resultado.bairros,
                cidades: endereco.resultado.cidades,
                logradouros: endereco.resultado.logradouros,
                loading: false
            }));
    }

    handleSelect = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : '')
        });
    };

    handleOnChangeFinalidade = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : ''),
            loading:true
        });

        this._services.getTiposImoveisOptions(e.target.value)
            .then(tipos => this.setState({tipos: tipos.options}));

        this._services.getbuscaEndereco(e.target.value,'')
            .then(endereco => this.setState({
                bairros: endereco.resultado.bairros,
                cidades: endereco.resultado.cidades,
                logradouros: endereco.resultado.logradouros ?? [],
                loading:false
            }));

    };

    renderRedirect = (e) => {
        e.preventDefault();
        this.props.history.push('/busca/'+this.state.finalidade.toLowerCase()+'/'+this.state.tipo.toLowerCase()+'/'+this.state.endereco.toLowerCase());
    };

    render() {
        const listBairros = 
            this.state.bairros.map((bairro) => (
                { text: bairro.text, id: Utils.transliterate(bairro.id) }
            ))
        ;

        const listCidades = 
            this.state.cidades.map((cidade) => (
                { text:cidade.text, id: Utils.transliterate(cidade.id) }
            ))


        // const listEmpreendimentos =
        //     this.state.empreendimentos.map((empreendimento) => (
        //         { text: empreendimento.text, id: Utils.transliterate(empreendimento.id) }
        //     ))
        // ;

        const listLogradouros =
            this.state.logradouros.map((logradouro) => (
                { text: logradouro.text, id: Utils.transliterate(logradouro.id) }
            ))
        ;

        let data = [];
        if (this.state.finalidade === 'aluguel') {
           data = [
                { text: 'Cidades',
                    children: listCidades,
                },
                { text: 'Bairros',
                    children: listBairros,
                }
            ]
        } else {
          data = [
                { text: 'Cidades',
                    children: listCidades,
                },
                { text: 'Bairros',
                    children: listBairros,
                },
                { text: 'Logradouros',
                    children: listLogradouros,
                }
            ]
        }

        return (
            <div id="search-location">
                <Form onSubmit={(e) => this.renderRedirect(e)}>
                    <div className="col2">
                        <Input
                            type="select"
                            name="finalidade"
                            onChange={(e) => this.handleOnChangeFinalidade(e)}
                            defaultValue={this.state.finalidade}>
                            <option value="venda">Comprar</option>
                            <option value="aluguel">Alugar</option>
                        </Input>
                    </div>
                    <div className="col3">
                        <Segment loading={this.state.loading} basic style={{"padding":"0"}}>
                            <Input
                                type="select"
                                name="tipo"
                                onChange={(e) => this.handleSelect(e)}>
                               {this.state.tipos.map((tipo, index) => (
                                   <option key={index} value={tipo.value}>{tipo.name}</option>
                               ))}
                            </Input>
                        </Segment>
                    </div>
                    <div className="col5">
                        <Segment loading={this.state.loading} basic style={{"padding":"0"}}>
                            <Select2
                                name="endereco"
                                className="form-control"
                                data={data}
                                options={{
                                    placeholder: 'Selecione um bairro ou cidade',
                                }}
                                value={this.state.endereco}
                                required={true}
                                onSelect={(e) => this.handleSelect(e)}
                            />
                        </Segment>
                    </div>
                    <div className="col2">
                        <Button label="Buscar" className="btn botao">Buscar</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

export default SearchLocation;
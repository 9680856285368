import React from 'react';
import Botao from './../../../components/Botao/Botao';

const Safearea = (props) => {
    return (
        <section>
            <div className="bairros-destaque">
                <div className="overlay"></div>
                <div className="wrap">
                    <h2>{props.data.bairro}, <span>{props.data.cidade} - {props.data.uf}</span></h2>
                </div>
            </div>
            <div className="link-form">
                <div className="col">
                    <h3>
                        Procurando um imóvel para comprar ou alugar em Benfica? A César Rêgo tem {props.data.totalImoveis} imóveis
                        anunciados no bairro e pode te ajudar a fazer um bom negócio.
                    </h3> 
                    <span>
                        <Botao anchor={true} to="contato" label = "Fale com a César Rêgo" />
                    </span>                     
                </div>                         
            </div>            
        </section>
    );
}
export default Safearea;
import React from 'react';
import SearchLocation from './SearchLocation';
import SearchCode from './SearchCode';

const Form = (props) => {
    let formulario;

    if (props.type === 'SearchLocation') {
        formulario = <SearchLocation {...props}/>
    }

    if (props.type === 'SearchCode') {
        formulario = <SearchCode {...props}/>
    }

    return (
        <div>
            { formulario }
        </div>
    );
}

export default Form;